<template>
  <div class="c-section__asset" :data-speed="speed" v-parallaxElement>
    <slot />
  </div>
</template>

<script>
import "@/utils/parallax-element.js";

export default {
  name: 'FloatElement',
  props: {
    speed: String
  }
}
</script>