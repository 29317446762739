<template>
  <nav class="c-main-nav">
    <div class="c-main-nav__container">
      <router-link to="/" title="Neugebauer" class="c-main-nav__ttl">
        <Logo class="c-main-nav__logo" />
      </router-link>

      <ul class="c-main-nav__list">
        <li 
          class="c-main-nav__item"
          v-for="(link, i) in links"
          :key="i"
        >
          <router-link
            class="c-main-nav__link"
            :to="link.url"
          >
            <span class="c-main-nav__txt">{{ link.name }}</span>
          </router-link>
        </li>
      </ul>

      <div class="c-main-nav__mob" @click="toggleMobNav">
        <NavIco class="c-main-nav__mob-ico c-main-nav__mob-ico--open" />
        <NavIcoClose class="c-main-nav__mob-ico c-main-nav__mob-ico--close" />
      </div>

    </div>
  </nav>
</template>

<script>
import Logo from '@/assets/imgs/svgs/logo-neugebauer.svg';
import NavIco from '@/assets/imgs/svgs/ico-nav.svg';
import NavIcoClose from '@/assets/imgs/svgs/ico-nav-close.svg';

export default {
  name: 'MainNav',
  components: {
    Logo,
    NavIco,
    NavIcoClose
  },
  data() {
    return {
      links: [
        {
          name: 'Histórias do Nino',
          url: '/#historias-do-nino'
        },
        {
          name: 'Páscoa em família',
          url: '/#pascoa-em-familia'
        },
        {
          name: 'Jogos',
          url: '/#jogos'
        },
        {
          name: 'Filtros',
          url: '/#filtros'
        },
        {
          name: 'Receitas',
          url: '/#receitas'
        }
      ]
    }
  },
  methods: {
    toggleMobNav() {
      document.querySelector('html').classList.toggle('is-mobile-nav-open');
    },
    showHideNav() {
      let prevScrollpos = window.pageYOffset;

      window.addEventListener('scroll', function() {
        const header = document.querySelector('.c-main-header');
        const headerHeight = header.offsetHeight;
        let currentScrollPos = window.pageYOffset;

        if (prevScrollpos < currentScrollPos && currentScrollPos > headerHeight) {
          header.classList.add('is-hidden');
        } else {
          header.classList.remove('is-hidden');
          currentScrollPos > headerHeight
            ? header.classList.add('is-scrolling')
            : header.classList.remove('is-scrolling');
        }

        prevScrollpos = currentScrollPos;
      });
    },
  },
  created() {
    this.showHideNav();
  }
}
</script>