import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Home from '@/views/Home.vue'
import OrigemDaPascoa from '@/views/OrigemDaPascoa.vue'
import MundoSemCor from '@/views/MundoSemCor.vue'
import MagicaFabrica from '@/views/MagicaFabrica.vue'
import PresenteDePascoa from '@/views/PresenteDePascoa.vue'
import MonteACesta from '@/views/MonteACesta.vue'
import PuloDoCoelho from '@/views/PuloDoCoelho.vue'
import TesourosDaNeugebauer from '@/views/TesourosDaNeugebauer.vue'
import EncontreONinho from '@/views/EncontreONinho.vue'
import PinturaFacial from '@/views/PinturaFacial.vue'
import ComoMontarACesta from '@/views/ComoMontarACesta.vue'
import CalendarioPascoal from '@/views/CalendarioPascoal.vue'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // This is a special route for the common project components
  // {
  //   path: '/componentes',
  //   name: 'Components',
  //   component: () => import('../views/Components.vue')
  // },
  {
    path: '/a-origem-da-pascoa',
    name: 'OrigemDaPascoa',
    component: OrigemDaPascoa
  },
  {
    path: '/o-mundo-sem-cor',
    name: 'MundoSemCor',
    component: MundoSemCor
  },
  {
    path: '/a-magica-fabrica-de-chocolates',
    name: 'MagicaFabrica',
    component: MagicaFabrica
  },
  {
    path: '/o-presente-de-pascoa',
    name: 'PresenteDePascoa',
    component: PresenteDePascoa
  },
  {
    path: '/monte-a-cesta',
    name: 'MonteACesta',
    component: MonteACesta
  },
  {
    path: '/o-pulo-do-coelho',
    name: 'PuloDoCoelho',
    component: PuloDoCoelho
  },
  {
    path: '/tesouros-da-neugebauer',
    name: 'TesourosDaNeugebauer',
    component: TesourosDaNeugebauer
  },
  {
    path: '/encontre-o-ninho',
    name: 'EncontreONinho',
    component: EncontreONinho
  },
  {
    path: '/pintura-facial',
    name: 'PinturaFacial',
    component: PinturaFacial
  },
  {
    path: '/como-montar-a-cesta',
    name: 'ComoMontarACesta',
    component: ComoMontarACesta
  },
  {
    path: '/calendario-pascoal',
    name: 'CalendarioPascoal',
    component: CalendarioPascoal
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function(to, from, savedPosition) {
    const page = document.querySelector('html');
    page.classList.contains('is-mobile-nav-open') && page.classList.remove('is-mobile-nav-open');
    if (to.hash) {
      return {
        selector: to.hash,
        behavior:  to.path === from.path ? 'smooth' : 'auto'
      } 
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
