<template>
  <Family
    head_title="Montar a Cesta"
    head_text="Está com dúvida de como montar a cesta de presente perfeita? Fique tranquilo, preparamos 5 passos que vão lhe ajudar nessa tarefa. Antes de tudo, tenha em mente como é a personalidade da pessoa que receberá o presente e do que ela gosta."
    :buttons="buttons"
  >

    <p class="o-ttl--upper o-ttl--20 o-ttl--black o-ttl--center u-mb-40">veja as opçÕes:</p>

    <ContentBox 
      :slider="slides"
    />

  </Family>
</template>

<script>
import Family from '@/components/templates/Family.vue'
import ContentBox from '@/components/ContentBox.vue'
import img_1 from '@/assets/imgs/family/escolha-a-cesta.png'
import img_2 from '@/assets/imgs/family/decore-a-cesta.png'
import img_3 from '@/assets/imgs/family/adicione-os-chocolates.gif'
import img_4 from '@/assets/imgs/family/enlace-a-cesta.png'

export default {
  name: 'ComoMontarACesta',
  components: {
    Family,
    ContentBox
  },
  data() {
    return {
      slides: [
        {
          box_title: '1. escolha a cesta',
          box_text: 'Pode parecer óbvio, mas leve em consideração o tamanho, o material e quanto ele pode suportar. Você pode escolher uma cesta de vime, de palha, caixa decorativa destampada ou até um caixote de madeira.',
          box_img: img_1,
        },
        {
          box_title: '2. decore a cesta',
          box_text: 'Antes de incluir os chocolates na cesta, decore a parte interna dela. Indica-se forrar com papel seda ou papel crepom. Outra possibilidade, se quiser uma proposta mais rústica, é incluir palha artificial ou tecido juta.',
          box_img: img_2,
        },
        {
          box_title: '3. adicione os chocolates',
          box_text: 'Chegou a melhor hora: rechear a cesta com os Chocolates Neugebauer. Lembre-se de colocar primeiro os maiores e mais resistentes, deixando os menores e mais frágeis por último. <b>Descubra o chocolate ideal para quem for presentear!</b>',
          btn_url: 'https://www.neugebauer.com.br/pt/produtos/chocolates/topo',
          btn_text: 'Conheça os produtos aqui!',
          box_img: img_3,
        },
        {
          box_title: '4. enlace a cesta',
          box_text: 'Por fim, com tudo dentro da cesta, agora é o momento dos enfeites. Você pode embrulhar todo o conjunto com um papel celofane ou apenas finalizar com fitas e um belo laço.',
          box_img: img_4,
        }
      ],
      buttons: [
        {
          url: '/encontre-o-ninho',
          name: 'Encontre O Ninho'
        },
        {
          url: '/pintura-facial',
          name: 'Pintura Facial'
        },
        {
          url: '/calendario-pascoal',
          name: 'Calendário Páscoal'
        }
      ]
    }
  }
}
</script>