<template>
  <footer class="c-main-footer">
    <div class="o-content-block o-content-block--1520">
        <Logo class="c-main-footer__logo" />

        <ul class="c-social">
          <li 
            class="c-social__item"
            v-for="(icon, i) in icons"
            :key="i"
          >
            <a :href="icon.url" target="_blank" class="c-social__link">
              <component :is="icon.ico"/>
            </a>
          </li>
        </ul>
    </div>
  </footer>
</template>

<script>
import Logo from '@/assets/imgs/svgs/logo-neugebauer.svg';
import web from '@/assets/imgs/svgs/ico-web.svg';
import facebook from '@/assets/imgs/svgs/ico-fb.svg';
import instagram from '@/assets/imgs/svgs/ico-ig.svg';
import linkedin from '@/assets/imgs/svgs/ico-in.svg';

export default {
  name: 'Footer',
  components: {
    Logo
  },
  data() {
    return {
      icons: [
        {
          ico: web,
          url: 'https://neugebauer.com.br/'
        },
        {
          ico: facebook,
          url: 'https://www.facebook.com/QueroNeugebauer/'
        },
        {
          ico: instagram,
          url: 'https://www.instagram.com/queroneugebauer/'
        },
        {
          ico: linkedin,
          url: 'https://www.linkedin.com/company/neugebauersa/'
        },
      ]
    }
  }
}
</script>