<template>
  <div>

    <div v-if="slider">
      <VueSlickCarousel 
      class="c-slider c-slider--family"
      v-bind="settings"
      >
        <div v-for="(slide, i) in slider" :key="i" class="c-slider__item">
          <div :class="['c-content-box', slide.mod_class]">
            <div class="c-content-box__text">
              <h3 class="o-ttl--secondary-darken o-ttl--20 o-ttl--black o-ttl--upper">{{ slide.box_title }}</h3>
              <p v-html="slide.box_text"></p>
  
              <Button
                v-if="slide.btn_url"
                :url="slide.btn_url"
                :text="slide.btn_text"
                class="o-btn--link"
              />
            </div>

            <div v-if="slide.box_img" class="c-content-box__img">
              <img :src="slide.box_img" :alt="slide.box_title" />
            </div>    
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    
    <div v-if="box_img">
      <div class="c-content-box__img">
        <img :src="box_img" :alt="box_title" />
      </div>    
    </div>

  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  name: "ContentBox",
  components: {
    Button,
    VueSlickCarousel,
  },
  props: {
    box_title: String,
    box_text: String,
    btn_url: String,
    btn_text: String,
    box_img: String,
    slider: Array
  },
  data() {
    return {
      settings: {
        dots: true,
        arrows: false
      }
    }
  }
};
</script>
