<template>
  <section class="c-hero">
      
      <kinesis-container :duration="1000">
        <div class="c-hero__content">
          <div class="c-hero__img c-hero__img--title">
            <kinesis-element tag="h1" :strength="-20">
              <Lettering />
            </kinesis-element>
          </div>
          <div class="c-hero__img c-hero__img--title-mobile">
            <kinesis-element tag="h1" :strength="-20">
              <LetteringMobile />
            </kinesis-element>
          </div>
          <div class="c-hero__img c-hero__img--nino">
            <kinesis-element :strength="15" tag="img" :src="nino_hero" alt="Coelho Nino" />
          </div>
          <div class="c-hero__img c-hero__img--bombom">
            <kinesis-element :strength="15" tag="img" :src="bombom" alt="Bombom" />
          </div>
          <div class="c-hero__img c-hero__img--chocolate">
            <kinesis-element :strength="15" tag="img" :src="chocolate" alt="Barra de Chocolate" />
          </div>
          <router-link to="/#neugebauer" class="c-hero__scroll">
            <ScrollDown />
          </router-link>
        </div>
      </kinesis-container>

  </section>
</template>

<script>
import ScrollDown from '@/assets/imgs/svgs/ico-scroll-down.svg';
import Lettering from '@/assets/imgs/svgs/lettering.svg';
import LetteringMobile from '@/assets/imgs/svgs/lettering-mobile.svg';
import nino_hero from '@/assets/imgs/home/nino-hero.png';
import family from '@/assets/imgs/home/familia-hero.png';
import bombom from '@/assets/imgs/home/bombom.png';
import chocolate from '@/assets/imgs/home/barra-chocolate.png';

export default {
  name: 'Hero',
  components: {
    Lettering,
    LetteringMobile,
    ScrollDown
  },
  data() {
    return {
      nino_hero,
      bombom,
      chocolate,
      family
    }
  }
}
</script>