import Vue from 'vue';

Vue.directive('parallaxElement',{
  inserted: function(el) {
    const objTop = el.getBoundingClientRect().top + window.pageYOffset - document.documentElement.clientTop;
    const objSpeed = el.dataset.speed;
    const winHeight = window.innerHeight;
    const parallaxEl = el.children[0];

    window.addEventListener('scroll', () => {
      const yPos = document.documentElement.scrollTop + winHeight;
      const objPos = Math.floor((yPos - objTop) / (objSpeed * 10));

      if (yPos >= objTop) {
        parallaxEl.style.transform = `translateY(${objPos}px)`;
      }
    })
  },
});