<template>
  <section class="c-section c-section--page c-section--primary u-detail u-detail--history-nino">
    <div class="o-content-block o-content-block--1270">

      <PageHeader 
        title_mod="o-ttl--secondary" 
        page_title="As Aventuras <br> <span>de Nino em...</span>"
        :title="head_title"
        :text="head_text"
      />

      <div class="c-story">
        <div class="c-story__wrapper">
          <InnerImageZoom 
            :src="story_image" 
            :alt="head_title" 
            class="c-story__img"
            :fullscreenOnMobile="true"
          />
        </div>
        <Button 
          :url="story_file" 
          text="baixe para colorir" 
          class="o-btn--small o-btn--small--primary" 
          :download="true"
        />
      </div>

    </div>

    <More mod_class="c-more--secondary">

      <div class="o-grid o-grid--inline o-grid--col-3 o-grid--col-xs-2 o-grid--gap-80">

        <div
          v-for="(button, i) in buttons"
          :key="i" 
          class="o-grid__item"
        >
          <Button 
            :url="button.url" 
            :text="button.name" 
            class="o-btn--card o-btn--card--local" 
          />
        </div>

      </div>

    </More>
  
  </section>
</template>

<script>
import InnerImageZoom from 'vue-inner-image-zoom';
import PageHeader from '@/components/PageHeader.vue';
import Button from '@/components/Button.vue';
import More from '@/components/More.vue';

export default {
  name: 'Adventures',
  metaInfo () {
    return {
      title: `${this.head_title}`,
      meta: [
        { 
          name: 'description', 
          content: `${this.head_text}` ,
          vmid: 'description'
        }
      ]
    }
  },
  components: {
    PageHeader,
    Button,
    More,
    InnerImageZoom,
  },
  props: {
    head_title: String,
    head_text: String,
    story_image: String,
    story_file: String,
    buttons: Array
  }
}
</script>