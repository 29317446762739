<template>
  <section id="pascoa-em-familia" class="c-section c-section--family c-section--cream u-detail u-detail--cream-1">
    <div class="o-content-block o-content-block--1270">

        <div class="c-section__header">
          <h2 class="o-ttl--50 o-ttl--primary o-ttl--black o-ttl--upper o-ttl--hybrid-orange">
            Páscoa
            <span>em família</span>
          </h2>
          <p>Preparamos 4 maneiras de aproveitar a Páscoa em família e criar momentos que ficarão eternizados na lembrança.</p>
        </div>

    </div>

    <div class="o-content-block o-content-block--704">

      <div class="o-grid o-grid--col-xs-1 o-grid--col-2 o-grid--gap-40 u-mt-60">

        <div
          v-for="(tradition, i) in traditions"
          :key="i" 
          class="o-grid__item"
        >
          <Button 
            :url="tradition.url" 
            :text="tradition.name" 
            class="o-btn o-btn--wide" 
          />
        </div>

      </div>

    </div>
  </section>
</template>

<script>
import Button from '@/components/Button.vue';
import FloatElement from '@/components/FloatElement.vue';
import Chat from '@/assets/imgs/svgs/chat.svg';
import Feet from '@/assets/imgs/svgs/feet.svg';
 
export default {
  name: 'Family',
  components: {
    Button,
    FloatElement,
    Chat,
    Feet
  },
  data() {
    return {
      traditions: [
        {
          name: 'Encontre o Ninho',
          url: '/encontre-o-ninho'
        },
        {
          name: 'Montar a Cesta',
          url: '/como-montar-a-cesta'
        },
        {
          name: 'Pintura Facial',
          url: '/pintura-facial'
        },
        {
          name: 'Calendário Pascoal',
          url: '/calendario-pascoal'
        }
      ]
    }
  }
}
</script>