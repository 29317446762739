<template>
  <Adventures
    head_title="o mundo sem cor"
    head_text="Certo dia, o Coelho Nino acorda e o mundo inteiro está branco, sem cores. Preocupado, ele embarca em uma jornada para descobrir como recolorir a vida ao seu redor."
    :story_image="o_mundo_sem_cor"
    :story_file="quadrinho"
    :buttons="buttons"
  />
</template>

<script>
import o_mundo_sem_cor from '@/assets/imgs/aventuras/as-cores-da-pascoa.png'
import Adventures from '@/components/templates/Adventures.vue'
import quadrinho from '@/assets/files/quadrinho-cores-da-pascoa.pdf'

export default {
  name: 'MundoSemCor',
  components: {
    Adventures
  },
  data() {
    return {
      o_mundo_sem_cor,
      quadrinho,
      buttons: [
        {
          url: '/a-origem-da-pascoa',
          name: 'A Origem Da Páscoa'
        },
        {
          url: '/o-presente-de-pascoa',
          name: 'O Presente de Páscoa'
        },
        {
          url: '/a-magica-fabrica-de-chocolates',
          name: 'A Mágica Fábrica de Chocolate'
        }
      ]
    }
  }
}
</script>