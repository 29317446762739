<template>
  <Games 
    head_title="Monte a Cesta"
    head_text="Clique nos chocolates para montar a cesta. <br> Cuidado com as bombas!"
    game_url="./games/monte-a-cesta/index.html"
    :buttons="buttons"
  />
</template>

<script>
import Games from '@/components/templates/Games.vue'

export default {
  name: 'MonteACesta',
  components: {
    Games
  },
  data() {
    return {
      buttons: [
        {
          url: '/o-pulo-do-coelho',
          name: 'O Pulo do Coelho'
        },
        {
          url: '/tesouros-da-neugebauer',
          name: 'Tesouros da Neuge'
        }
      ]
    }
  }
}
</script>