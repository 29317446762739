<template>
  <section id="neugebauer" class="c-section c-section--neugebauer c-section--secondary u-detail u-detail--orange-1">
    <div class="o-content-block o-content-block--1520">

      <div class="c-section__header">
        <h2 class="o-ttl--50 o-ttl--black o-ttl--hybrid-white">
          PÁSCOA COM SABOR DE
          <span>NEUGEBAUER!</span>
        </h2>
        <p class="o-ttl--20">Nada melhor do que celebrar a Páscoa em família com muitos abraços, infinitas conversas e risadas contagiantes. Para tornar esses momentos ainda mais especiais, é só acrescentar mais sabor. A Páscoa com Sabor de Neugebauer é um convite à conexão emocional, criando memórias e lembranças com aqueles que amamos.</p>
        <strong>Para deixar aquele sabor delicioso <br> de Neugebauer em todos os momentos!</strong>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'Intro'
}
</script>