<template>

  <!-- External -->
  <a 
    v-if="isExternal"
    :href="url" 
    target="_blank"
    :class="['o-btn', isDisabled]"
  >
    <span class="o-btn__content">
      {{ text }}
      <img class="o-btn__img" v-if="img" :src="img" :alt="text">
    </span>
  </a>

  <!-- Download -->
  <a 
    v-else-if="download"
    :href="url"
    :class="['o-btn o-btn--small--download', isDisabled]"
    download
  >
    <span class="o-btn__content">
      {{ text }}
    </span>
  </a>

  <!-- Local -->
  <router-link 
    v-else
    :to="url" 
    :class="['o-btn', isDisabled]"
  >
    <span class="o-btn__content" >
      {{ text }}
    </span>
  </router-link>

</template>

<script>
export default {
  name: 'Button',
  props: {
    url: String,
    text: String,
    img: String,
    disabled: Boolean,
    download: Boolean
  },
  computed:{
    isExternal(){
      return typeof this.url === 'string' && this.url.startsWith('http')
    },
    isDisabled(){
      return this.disabled && 'o-btn--disabled';
    }
  }
}
</script>