<template>
  <section id="filtros" class="c-section c-section--insta c-section--primary u-detail u-detail--brown-2">
    <div class="o-content-block o-content-block--1050">

      <div class="c-filters">
        <div class="c-filters__header">
          <h2 class="o-ttl--50 o-ttl--secondary o-ttl--black o-ttl--upper o-ttl--hybrid-white">
            Filtros
            <span>do Insta!</span>
          </h2>
          <p>Experimente os filtros do Instagram da Neugebauer e veja com qual chocolate você mais combina!</p>
        </div>
  
        <VueSlickCarousel class="c-slider c-slider--filters c-filters__slider" v-bind="settings">
          <div v-for="(filter, i) in filters" :key="i" class="c-slider__item">
            <a :href="filter.url" class="c-slider__link">
              <img :src="filter.img" alt="Filtro do Instagram" class="c-slider__img">
            </a>
          </div>
        </VueSlickCarousel>

        <div class="c-filters__link">
          <Button 
            url="https://www.instagram.com/queroneugebauer/" 
            text="Veja mais aqui!" 
            class="o-btn o-btn--link" 
          />
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import Button from '@/components/Button.vue';
import FloatElement from '@/components/FloatElement.vue';
import Smartphone from '@/assets/imgs/svgs/smartphone.svg';
import Instagram from '@/assets/imgs/svgs/instagram.svg';
import filtro_1 from '@/assets/imgs/home/filtro-1.jpg';
import filtro_2 from '@/assets/imgs/home/filtro-2.jpg';

export default {
  name: 'Filters',
  components: {
    Button,
    VueSlickCarousel,
    FloatElement,
    Smartphone,
    Instagram
  },
  data() {
    return {
      filters: [
        {
          img: filtro_1,
          url: 'https://www.instagram.com/ar/220955850517162'
        },
        {
          img: filtro_2,
          url: 'https://www.instagram.com/ar/951444239228044'
        }
      ],
      settings: {
        'focusOnSelect': true,
        'infinite': true,
        'slidesToShow': 2,
        "responsive": [
          {
            "breakpoint": 679,
            "settings": {
              "slidesToShow": 1
            }
          }
        ]
      }
    }
  }
}
</script>