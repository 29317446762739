<template>
  <Adventures 
    head_title="O Presente de Páscoa"
    head_text="Enquanto a família se reúne na Páscoa, a filha, que mora na capital, não conseguirá participar das festividades. Sabendo disso, a mãe dela envia um presente especial."
    :story_image="o_presente_de_pascoa"
    :story_file="quadrinho"
    :buttons="buttons"
  />
</template>

<script>
import o_presente_de_pascoa from '@/assets/imgs/aventuras/o-presente-de-pascoa.png'
import Adventures from '@/components/templates/Adventures.vue'
import quadrinho from '@/assets/files/quadrinho-presente-de-pascoa.pdf'

export default {
  name: 'PresenteDePascoa',
  components: {
    Adventures
  },
  data() {
    return {
      o_presente_de_pascoa,
      quadrinho,
      buttons: [
        {
          url: '/o-mundo-sem-cor',
          name: 'Mundo Sem Cor'
        },
        {
          url: '/a-origem-da-pascoa',
          name: 'A Origem da Páscoa'
        },
        {
          url: '/a-magica-fabrica-de-chocolates',
          name: 'A Mágica Fábrica de Chocolates'
        }
      ]
    }
  }
}
</script>