<template>
  <section id="historias-do-nino" class="c-section c-section--stories c-section--primary u-detail u-detail--brown-1">
    <div class="o-content-block o-content-block--1270">

      <div class="c-section__header">
        <h2 class="o-ttl--50 o-ttl--secondary o-ttl--upper o-ttl--black o-ttl--hybrid-white">
          As Aventuras
          <span>de Nino em...</span>
        </h2>
        <p>Conheça 4 histórias do Coelho Nino <br> e redescubra a magia da Páscoa.</p>
      </div>

      <div class="c-adventures">

        <div class="o-grid o-grid--inline o-grid--col-4 o-grid--gap-30">

          <div 
            v-for="(adventure, i) in adventures"
            :key="i"
            class="o-grid__item"
          >
            <Button 
              :url="adventure.url" 
              :text="adventure.name" 
              class="o-btn--card o-btn--card--local" 
            />
          </div>

        </div>

      </div>

    </div>

  </section>
</template>

<script>
import Button from '@/components/Button.vue';

export default {
  name: 'Adventures',
  components: {
    Button
  },
  data() {
    return {
      adventures: [
        {
          name: 'A Origem da Páscoa',
          url: '/a-origem-da-pascoa'
        },
        {
          name: 'Mundo Sem Cor',
          url: '/o-mundo-sem-cor'
        },
        {
          name: 'O Presente de Páscoa',
          url: '/o-presente-de-pascoa'
        },
        {
          name: 'A Mágica Fábrica de Chocolates',
          url: '/a-magica-fabrica-de-chocolates'
        }
      ]
    }
  }
}
</script>