<template>
  <section id="jogos" class="c-section c-section--games c-section--secondary-darken u-detail u-detail--secondary-darken-1">
    <div class="o-content-block o-content-block--1520">

      <kinesis-container :duration="1000">
        <div class="c-games">

          <div class="c-games__header c-section__header">
            <h2 class="o-ttl--50 o-ttl--primary o-ttl--upper o-ttl--black o-ttl--hybrid-white">
              Jogos
              <span>de Páscoa</span>
            </h2>
            <p class="o-ttl--white">Divirta-se com o Coelho Nino <br> em suas aventuras!</p>
          </div>

          <div class="c-games__img">
            <kinesis-element :strength="15" tag="img" :src="nino_box" alt="Páscoa Neugebauer - Nino" />
          </div>

          <div class="c-games__links">

            <div class="o-grid o-grid--inline o-grid--col-xs-1 o-grid--col-3 o-grid--gap-60">

              <div 
                class="o-grid__item"
                v-for="(game, i) in games"
                :key="i"
              >
                <Button 
                  :url="game.url" 
                  :text="game.name"
                  class="o-btn--card o-btn--card--brown o-btn--card--local" 
                  :disabled="game.disabled"
                />
              </div>

            </div>

          </div>

        </div>
      </kinesis-container>

    </div>

  </section>
</template>

<script>
import "@/utils/parallax-element.js";
import nino_box from '@/assets/imgs/home/nino-box.png';
import Button from '@/components/Button.vue';

export default {
  name: 'Games',
  components: {
    Button
  },
  data() {
    return {
      nino_box,
      games: [
        {
          name: 'O Pulo do Coelho',
          url: '/o-pulo-do-coelho'
        },
        {
          name: 'Tesouros da Neuge',
          url: '/tesouros-da-neugebauer'
        },
        {
          name: 'Monte a Cesta',
          url: '/monte-a-cesta'
        }
      ]
    }
  }
}
</script>