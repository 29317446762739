<template>

  <div>

    <Hero />

    <Intro />

    <Adventures />

    <Family />

    <Games />

    <Filters />

    <Recipes />

  </div>

</template>

<script>
import Button from '@/components/Button.vue';
import Hero from '@/components/home/Hero.vue';
import Intro from '@/components/home/Intro.vue';
import Adventures from '@/components/home/Adventures.vue';
import Family from '@/components/home/Family.vue';
import Games from '@/components/home/Games.vue';
import Filters from '@/components/home/Filters.vue';
import Recipes from '@/components/home/Recipes.vue';

export default {
  name: 'Home',
  components: {
    Button,
    Hero,
    Intro,
    Adventures,
    Family,
    Games,
    Filters,
    Recipes,
  },
  data() {
    return {
      theme: 't-primary'
    }
  },
  methods: {
    addTheme(event) {
      this.$emit('load', 't-primary');
    }
  }
}
</script>
