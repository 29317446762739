<template>
  <Games 
    head_title="Tesouros Da Neuge"
    head_text="Forme grupos com os chocolates que são iguais e marque pontos."
    game_url="./games/tesouros-da-neugebauer/index.html"
    :buttons="buttons"
  />
</template>

<script>
import Games from '@/components/templates/Games.vue'

export default {
  name: 'TesourosDaNeugebauer',
  components: {
    Games
  },
  data() {
    return {
      buttons: [
        {
          url: '/o-pulo-do-coelho',
          name: 'O Pulo do Coelho'
        },
        {
          url: '/monte-a-cesta',
          name: 'Monte a Cesta'
        }
      ]
    }
  }
}
</script>