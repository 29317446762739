<template>
  <section
    class="c-section c-section--page c-section--secondary u-detail u-detail--family"
  >
    <div class="o-content-block o-content-block--1270">
      <PageHeader
        title_mod="o-ttl--primary o-ttl--line-bronw"
        page_title="Páscoa <br> <span>em Família</span>"
        :title="head_title"
        :text="head_text"
        :other="head_other"
      />
    </div>

    <!-- Conteúdo Aqui -->
    <div class="o-content-block o-content-block--1270">
      <slot />
    </div>

    <More mod_class="c-more--primary">
      <div class="o-grid o-grid--col-1 o-grid--gap-40">

        <div 
          v-for="(button, i) in buttons" 
          :key="i" 
          class="o-grid__item"
        >
          <Button
            :url="button.url"
            :text="button.name"
            class="o-btn--shadow o-btn--wide"
          />
        </div>

      </div>
    </More>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Button from "@/components/Button.vue";
import More from "@/components/More.vue";

export default {
  name: "Family",
  metaInfo() {
    return {
      title: `${this.head_title}`,
      meta: [
        { 
          name: 'description', 
          content: `${this.head_text}` ,
          vmid: 'description'
        }
      ]
    };
  },
  components: {
    PageHeader,
    Button,
    More,
  },
  props: {
    head_title: String,
    head_text: String,
    head_other: String,
    buttons: Array
  },
};
</script>
