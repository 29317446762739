<template>
  <Games 
    head_title="O Pulo Do Coelho"
    head_text="Desvie das árvores e ajude o Coelho Nino a cruzar a floresta mágica."
    game_url="./games/pulo-do-coelho/index.html"
    :buttons="buttons"
  />
</template>

<script>
import Games from '@/components/templates/Games.vue'

export default {
  name: 'PuloDoCoelho',
  components: {
    Games
  },
  data() {
    return {
      buttons: [
        {
          url: '/monte-a-cesta',
          name: 'Monte a Cesta'
        },
        {
          url: '/tesouros-da-neugebauer',
          name: 'Tesouros da Neuge'
        }
      ]
    }
  }
}
</script>