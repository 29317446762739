<template>

  <div id="app">
    
    <Header :class="altHeader" />

    <main class="c-main-content">
      <router-view />
    </main>

    <Footer :class="altFooter" />

  </div>

</template>

<script>
import Header from '@/components/header/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  metaInfo: {
    titleTemplate: (titleChunk) => {
      return titleChunk ? `${titleChunk} | Páscoa Neugebauer` : 'Páscoa Neugebauer';
    },
    meta: [
      { 
        name: 'description', 
        content: 'Nada melhor do que celebrar a Páscoa em família com muitos abraços, infinitas conversas, risadas contagiantes e presentes deliciosos.',
        vmid: 'description'
      }
    ]
  },
  components: {
    Header,
    Footer
  },
  data() {
    return {
      headerAltRoutes: [
        '/a-origem-da-pascoa',
        '/o-mundo-sem-cor',
        '/o-presente-de-pascoa',
        '/a-magica-fabrica-de-chocolates',
        '/monte-a-cesta',
        '/o-pulo-do-coelho',
        '/tesouros-da-neugebauer'
      ],
      footerAltRoutes: [
        '/encontre-o-ninho',
        '/pintura-facial',
        '/como-montar-a-cesta',
        '/calendario-pascoal',
      ]
    }
  },
  computed: {
    altHeader() {
      if (this.headerAltRoutes.includes(this.$route.path))
        return 'c-main-header--alt';
    },
    altFooter() {
      if (this.footerAltRoutes.includes(this.$route.path))
        return 'c-main-footer--alt';
    }
  }
}
</script>

<style lang="scss">

</style>
