<template>
  <div class="c-section__header"> 
    <h2 :class="['o-ttl--20 o-ttl--line o-ttl--upper o-ttl--black o-ttl--hybrid-white', title_mod]" v-html="page_title"></h2>
    <h1 class="o-ttl--50 o-ttl--white o-ttl--center o-ttl--black o-ttl--upper">{{ title }}</h1>
    <p v-html="text"></p>
    <strong v-if="other">{{ other }}</strong>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title_mod: String,
    page_title: String,
    title: String,
    text: String,
    other: String
  }
}
</script>