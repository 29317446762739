<template>
   <Family
    head_title="encontre o ninho"
    head_text="Uma das diversões da Páscoa é a caça aos chocolates. Para este momento ficar ainda mais especial, separamos algumas dicas de como criar as pegadas do coelho."
    :buttons="buttons"
  >

    <p class="o-ttl--upper o-ttl--20 o-ttl--black o-ttl--center u-mb-40">saiba como criar as pegadas do coelho:</p>

    <ContentBox 
      :slider="slides"
    />

  </Family>
</template>

<script>
import Family from '@/components/templates/Family.vue'
import ContentBox from '@/components/ContentBox.vue'
import Button from '@/components/Button.vue'
import img_1 from '@/assets/imgs/family/farinha.png'
import img_2 from '@/assets/imgs/family/talco.png'
import img_3 from '@/assets/imgs/family/folha-de-e-v-a.png'

export default {
  name: 'EncontreONinho',
  components: {
    Family,
    ContentBox,
    Button
  },
  data() {
    return {
      slides: [
        {
          box_title: '1. farinha',
          box_text: 'Em uma xícara, adicione duas colheres grandes de farinha com um pouco de água até formar uma pasta. Em seguida, molhe a ponta de 3 dedos e pressione em uma superfície. Repita esse processo com o polegar e finalize a pata. Deixe secar. Depois de seca, é fácil de limpar e não espalha farinha pela casa.',
          box_img: img_1,
        },
        {
          box_title: '2. talco',
          box_text: 'Em um papel, desenhe e recorte a pata do coelho para ter um molde. Em seguida, posicione a folha no local desejado e aplique o talco sobre ela. Remova com cuidado. A patinha do coelho está pronta.',
          box_img: img_2,
        },
        {
          box_title: '3. folha de e.v.a.',
          box_text: 'Você vai precisar de uma folha colorida de EVA e uma folha de cartolina de outra cor. Primeiro, desenhe o modelo das patas, são 3 círculos pequenos e 1 maior; recorte-os no EVA. Desenhe e recorte, na cartolina, o contorno, que deve ser maior do que a patinha. Depois, é só colar os círculos de EVA na cartolina e está pronta!',
          box_img: img_3,
        }
      ],
      buttons: [
        {
          url: '/pintura-facial',
          name: 'Pintura Facial'
        },
        {
          url: '/como-montar-a-cesta',
          name: 'Montar A Cesta'
        },
        {
          url: '/calendario-pascoal',
          name: 'Calendário Páscoal'
        }
      ]
    }
  }
}
</script>