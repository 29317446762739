<template>
  <div class="o-content-block o-content-block--1520">

    <div :class="['c-more', mod_class]">

      <h3 class="o-ttl--20 o-ttl--center o-ttl--white o-ttl--black o-ttl--upper">Veja também</h3>

      <slot />

    </div>

  </div>
</template>

<script>
export default {
  name: 'More',
  props: {
    mod_class: String
  }
}
</script>