<template>
  <Adventures 
    head_title="A Mágica Fábrica de Chocolate"
    head_text="Reza a lenda que muito distante da cidade, existe uma floresta onde tudo é mágico, os animais, as plantas, os rios. Lá está localizada a fábrica de chocolates do Coelho Nino."
    :story_image="a_magica_fabrica_de_chococolates"
    :story_file="quadrinho"
    :buttons="buttons"
  />
</template>

<script>
import a_magica_fabrica_de_chococolates from '@/assets/imgs/aventuras/a-magica-fabrica-de-chocolates.png'
import Adventures from '@/components/templates/Adventures.vue'
import quadrinho from '@/assets/files/quadrinho-magica-fabrica.pdf'

export default {
  name: 'MagicaFabrica',
  components: {
    Adventures
  },
  data() {
    return {
      a_magica_fabrica_de_chococolates,
      quadrinho,
      buttons: [
        {
          name: 'Mundo Sem Cor',
          url: '/o-mundo-sem-cor'
        },
        {
          url: '/a-origem-da-pascoa',
          name: 'A Origem da Páscoa'
        },
        {
          url: '/o-presente-de-pascoa',
          name: 'O Presente de Páscoa'
        }
      ]
    }
  }
}
</script>