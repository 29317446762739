<template>
  <section id="receitas" class="c-section c-section--recipes c-section--cream u-detail u-detail--cream-2">
    <div class="o-content-block o-content-block--1270">

      <div class="c-section__header">
        <h2 class="o-ttl--50 o-ttl--primary o-ttl--black o-ttl--upper o-ttl--hybrid-orange">
          RECEITAS
          <span>DA NEUGE</span>
        </h2>
        <p>Conheça algumas receitas temáticas <br> de Páscoa no site da Neugebauer.</p>
      </div>

      <div class="c-recipes">

        <div class="o-grid o-grid--inline o-grid--col-4 o-grid--gap-30 u-mt-60">

          <div 
            class="o-grid__item"
            v-for="(recipe, i) in recipes"
            :key="i"
          >
            <Button 
              :url="recipe.url" 
              :text="recipe.name" 
              class="o-btn--card o-btn--card--img o-btn--card--external" 
              :img="recipe.img"
            />

          </div>

        </div>

      </div>

    </div>
  </section>
</template>

<script>
import casquinha_de_chocolate from '@/assets/imgs/home/receita-casquinha-de-chocolate.jpg';
import ovo_stikadinho from '@/assets/imgs/home/receita-ovo-stikadinho.jpg';
import ovo_bibs_trufado from '@/assets/imgs/home/receita-ovo-bibs-trufado.jpg';
import ovo_bem_casado from '@/assets/imgs/home/receita-ovo-bem-casado.jpg';

import Button from '@/components/Button.vue';

export default {
  name: 'Recipes',
  components: {
    Button,
  },
  data() {
    return {
      recipes: [
        {
          name: 'Ovo Bib\'s Trufado',
          img: ovo_bibs_trufado,
          url: 'https://neugebauer.com.br/pt/receitas/ovo-trufado-bibs'
        },
        {
          name: 'Ovo Stikadinho',
          img: ovo_stikadinho,
          url: 'https://neugebauer.com.br/pt/receitas/ovo-stikadinho'
        },
        {
          name: 'Casquinha de Chocolate',
          img: casquinha_de_chocolate,
          url: 'https://neugebauer.com.br/pt/receitas/casquinha-de-chocolate'
        },
        {
          name: 'Ovo Bem Casado',
          img: ovo_bem_casado,
          url: 'https://neugebauer.com.br/pt/receitas/ovo-bem-casado'
        }
      ]
    }
  }
}
</script>