<template>
  <Family
    head_title="pintura facial"
    head_text="A diversão da Páscoa fica ainda mais completa com a pintura facial, Você se sente parte de toda a magia da data ao se transformar no próprio coelhinho!"
    :buttons="buttons"
  >

    <p class="o-ttl--upper o-ttl--20 o-ttl--black o-ttl--center u-mb-40">confira as dicas:</p>

    <ContentBox 
      :slider="slides"
    />

  </Family>
</template>

<script>
import Family from '@/components/templates/Family.vue'
import ContentBox from '@/components/ContentBox.vue'
import img_1 from '@/assets/imgs/family/como-pintar-o-rosto.png'
import img_2 from '@/assets/imgs/family/coelho-express.png'
import img_3 from '@/assets/imgs/family/coelho-dentuco.png'
import img_4 from '@/assets/imgs/family/lembre-se.png'

export default {
  name: 'PinturaFacial',
  components: {
    Family,
    ContentBox
  },
  data() {
    return {
      slides: [
        {
          box_title: '1. como pintar o rosto',
          box_text: 'Antes de iniciar qualquer maquiagem artística, da mais simples à mais elaborada, atente-se aos <b>materiais necessários</b>: Tinta facial cremosa ou líquida das cores: rosa, preto e branco; 1 pincel língua de gato N° 16 e um pincel filete.',
          box_img: img_1,
        },
        {
          mod_class: 'c-content-box--secondary',
          box_title: '2. coelho express',
          box_text: 'Usando o pincel fino e a tinta preta, faça um círculo na ponta do nariz e, em seguida, 3 linhas retas em cada bochecha. Está pronto! Se quiser incrementar a maquiagem, você pode adicionar um pouco de tinta branca sobre a boca da criança com alguns pontinhos pretos para simular o focinho.',
          box_img: img_2,
        },
        {
          mod_class: 'c-content-box--secondary',
          box_title: '3. coelho dentuço',
          box_text: 'Inicie a maquiagem desenhando o nariz com a tinta rosa, pode ser no formato de círculo ou de coração. Em seguida, desenhe o bigode do coelho como na imagem. Por fim, na parte inferior da boca, desenhe 2 dentes, contorne-os com a tinta preta. Está pronto!',
          box_img: img_3,
        },
        {
          mod_class: 'c-content-box--secondary',
          box_title: 'lembre-se!',
          box_text: 'Leia o rótulo destes produtos para garantir que a tinta escolhida não agrida a pele sensível da criança. Para compor a maquiagem artística, você ainda pode recortar ou usar tiaras com orelhas de coelho.',
          box_img: img_4,
        }
      ],
      buttons: [
        {
          url: '/encontre-o-ninho',
          name: 'Encontre O Ninho'
        },
        {
          url: '/como-montar-a-cesta',
          name: 'Montar A Cesta'
        },
        {
          url: '/calendario-pascoal',
          name: 'Calendário Páscoal'
        }
      ]
    }
  }
}
</script>