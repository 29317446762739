<template>
  <section class="c-section c-section--page c-section--primary u-detail u-detail--games">
    <div class="o-content-block o-content-block--1270">

      <PageHeader 
        title_mod="o-ttl--secondary" 
        page_title="Jogos <br> <span>de Páscoa</span>"
        :title="head_title"
        :text="head_text"
      />

    </div>

    <div  
      :class="[{ 'is-full' : isFullScreen }, 'c-game-full']"
    >

      <div class="c-game">
        <div class="c-game__wrapper">
          <iframe 
            class="c-game__frame" 
            width="800" 
            height="500" 
            :src="game_url" 
            frameborder="0"
          ></iframe>
        </div>
      </div>

      <NavIcoClose
        @click="toggleFullScreen($event)" 
        class="c-game-full__close" 
      />

    </div>

    <div class="o-content-block o-content-block--800">
      <p class="o-text-small u-text-center" v-html="game_info"></p>

      <div class="c-game-full-btn">
        <a
          @click="toggleFullScreen($event)" 
          href="#" 
          class="o-btn o-btn--small o-btn--small--primary"
        >
          <div class="o-btn__content">Tela Cheia</div>
        </a>
      </div>

    </div>

    <More mod_class="c-more--secondary">

      <div class="o-grid o-grid--inline o-grid--col-2 o-grid--col-xs-2 o-grid--gap-80">

        <div 
          v-for="(button, i) in buttons"
          :key="i"
          class="o-grid__item"
        >
          <Button 
            :url="button.url" 
            :text="button.name" 
            class="o-btn--shadow o-btn--card o-btn--card--local"
            :disabled="button.disabled"
          />
        </div>

      </div>

    </More>
  
  </section>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import Button from '@/components/Button.vue';
import More from '@/components/More.vue';
import NavIcoClose from '@/assets/imgs/svgs/ico-nav-close.svg';

export default {
  name: 'Games',
  metaInfo () {
    return {
      title: `${this.head_title}`,
      meta: [
        { 
          name: 'description', 
          content: `${this.head_text}` ,
          vmid: 'description'
        }
      ]
    }
  },
  components: {
    PageHeader,
    Button,
    More,
    NavIcoClose,
  },
  data() {
    return {
      isFullScreen: false
    }
  },
  props: {
    head_title: String,
    head_text: String,
    game_url: String,
    game_info: String,
    buttons: Array
  },
  methods: {
    toggleFullScreen(e) {
      e.preventDefault();

      this.isFullScreen = !this.isFullScreen;
    }
  }
}
</script>