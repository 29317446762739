<template>
  <Family
    head_title="Calendário Pascoal"
    head_text="Você também é do tipo de pessoa que conta os dias para a Páscoa? Que tal transformar essa expectativa em um momento de diversão em família? Criamos um calendário de contagem regressiva. Em cada dia, uma tarefa diferente para reviver as tradições da Páscoa."
    :buttons="buttons"
  >

    <ContentBox 
      class="c-content-box--full-img"
      :box_img="img_1"
      box_title="Calendário"
    />

    <div class="c-section__action">

      <Button 
        :url="calendar" 
        text="baixe o calendário" 
        class="o-btn--small o-btn--small--secondary"
        :download="true"
      />

    </div>

  </Family>
</template>

<script>
import Family from '@/components/templates/Family.vue'
import ContentBox from '@/components/ContentBox.vue'
import Button from '@/components/Button.vue'
import img_1 from '@/assets/imgs/family/calendario.jpg'
import calendar from '@/assets/files/calendario-pascoal.pdf'

export default {
  name: 'CalendarioPascoal',
  components: {
    Family,
    ContentBox,
    Button
  },
  data() {
    return {
      img_1,
      calendar,
      buttons: [
        {
          url: '/pintura-facial',
          name: 'Pintura Facial'
        },
        {
          url: '/como-montar-a-cesta',
          name: 'Montar A Cesta'
        },
        {
          url: '/encontre-o-ninho',
          name: 'Encontre O Ninho'
        }
      ]
    }
  }
}
</script>