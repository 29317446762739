<template>
  <Adventures 
    head_title="A Origem da Páscoa"
    head_text="Depois de se questionar como surgiu a Páscoa, o Coelho Nino é transportado para o passado. Lá, ele encontra o primeiro Coelho de Páscoa, o seu tataravô."
    :story_image="a_origem_da_pascoa"
    :story_file="quadrinho"
    :buttons="buttons"
  />
</template>

<script>
import a_origem_da_pascoa from '@/assets/imgs/aventuras/a-origem-da-pascoa.png'
import Adventures from '@/components/templates/Adventures.vue'
import quadrinho from '@/assets/files/quadrinho-origem-da-pascoa.pdf'

export default {
  name: 'OrigemDaPascoa',
  components: {
    Adventures
  },
  data() {
    return {
      a_origem_da_pascoa,
      quadrinho,
      buttons: [
        {
          name: 'Mundo Sem Cor',
          url: '/o-mundo-sem-cor'
        },
        {
          url: '/o-presente-de-pascoa',
          name: 'O Presente de Páscoa'
        },
        {
          url: '/a-magica-fabrica-de-chocolates',
          name: 'A Mágica Fábrica de Chocolates'
        }
      ]
    }
  }
}
</script>