<template>
  <header class="c-main-header">
    <div class="o-content-block o-content-block--1520">
      <MainNav />
    </div>
  </header>
</template>

<script>
import MainNav from "@/components/header/MainNav.vue";

export default {
  name: 'Header',
  components: {
    MainNav
  }
}
</script>